<section class="relative z-10 flex flex-col w-full h-full mx-auto max-w-1920 gap-x-30 gap-y-30 p-30">
  <div class="flex flex-row items-center mb-20 md:mb-30" @fadeIn>
    <h2 class="mr-auto text-xl font-bold leading-none"
        @fadeInOut><span>Welcome!</span>
    </h2>
  </div>
  <section class="flex flex-col gap-y-20 md:gap-y-30">
    <div class="flex flex-col w-400 gap-y-10">
     
    </div>
  </section>
</section>