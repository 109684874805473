import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-solicitation-options',
  templateUrl: './solicitation-options.component.html',
})
export class SolicitationOptionsComponent {
  @Input() solicitationFormGroup?: UntypedFormGroup;
  solicitationOptions = [
    { name: 'IAR initiated transaction request (Solicited)', value: true },
    { name: 'Client initiated transaction request (Unsolicited)', value: false }
  ];
}
