import { Component } from '@angular/core';
import { fadeIn, fadeInOut } from 'src/app/shared/constants/animations';
import { Permission } from 'src/app/core/clients/generated/client';

@Component({
  animations: [fadeIn, fadeInOut],
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
})
export class DashboardPageComponent {

  constructor() { }

  perm = Permission;
}
