import { Component } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { OptOutClient } from 'src/app/core/clients/generated/client';
import { fadeIn } from 'src/app/shared/constants/animations';
import { portalV2OptInCookieName } from 'src/app/shared/constants/opt-in-cookie.constant';
import { environment } from 'src/environments/environment';

@Component({
  animations: [fadeIn],
  selector: 'app-optout-banner',
  templateUrl: './new-portal-optout.component.html'
})

export class NewPortalOptoutComponent {
  domain = '.alphastarcm.com';
  constructor(
    private cookieService: CookieService,
    private optOutClient: OptOutClient
  ) { }

  optOutOfNewPortal(): void {
    this.optOutClient.logNewPortalOptOut().subscribe(() => {
      const oneYearFromToday = new Date(new Date().setFullYear(2023));
      this.cookieService.set(portalV2OptInCookieName, 'False', { domain: this.domain, path: '/', expires: oneYearFromToday });
      window.location.href = environment.oldPortalURL;
    });
  }
}